import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography, Slider, Button, Input} from "@material-ui/core";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';

import PropTypes from 'prop-types';



const useStyles = makeStyles(theme => ({
    headerContainer:{
        backgroundColor: "#2f6472",
        padding: "2%",
        "& h5": {
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "12px",
              },
        }
    },
    stickyClass:{
        position: "sticky",
        top: "0",
        zIndex: "9",
    },
    stepTitle: {
        textTransform: "uppercase",
        color: "white",
        fontWeight: "bold",
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "18px",
          },
    },
    
}));

 export const HeaderHouseInsurance = ({title}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation('common');
    const insuranceHousePrice = useSelector((state) => state.houseInsurance);
    const insuranceMultimediaPrice = useSelector((state) => state.multimediaInsurance);


    return (
        <Grid item className={classes.stickyClass}>
            <Grid container className={classes.headerContainer}>
                    <Grid item xs={6} sm={6}>
                        <Typography variant="h2" className={classes.stepTitle}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={3} />
                    <Grid item xs={5} sm={3} >
                        <Grid container className={classes.headerContainer}>
                            <Grid item xs={12} >
                                {insuranceHousePrice.data.length !== 0 &&
                                    <Typography variant="h5" style={{color: "white"}}>
                                        {t('insurance.housing.step2.yourPrice',{ price: (insuranceHousePrice.data.unit_amount / 100).toFixed(2), reccuring: insuranceHousePrice.data.recurring.interval === "month" ? "/mois" : "/année" })}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} spacing={0}>
                                {insuranceMultimediaPrice.dataDetails !== null && insuranceMultimediaPrice.dataDetails.active &&
                                    <Typography variant="h5" style={{color: "white"}}>
                                        + { insuranceMultimediaPrice.dataDetails.active ? (insuranceMultimediaPrice.dataDetails.unit_amount / 100).toFixed(2) : "0"}€ (Option Multimédia)
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} spacing={0}>
                                {insuranceMultimediaPrice.dataDetails !== null &&
                                    <Typography variant="h5" style={{color: "white", fontWeight: "bold"}}>
                                        Total : { insuranceMultimediaPrice.dataDetails.active ? parseFloat((insuranceMultimediaPrice.dataDetails.unit_amount / 100).toFixed(2)) + parseFloat((insuranceHousePrice.data.unit_amount / 100).toFixed(2)) : (insuranceHousePrice.data.unit_amount / 100).toFixed(2) }€ {insuranceHousePrice.data.recurring.interval === "month" ? "/mois" : "/année"}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>


                    </Grid>
            </Grid>
        </Grid>
 );
};

HeaderHouseInsurance.propTypes = {
    title: PropTypes.string,
    priceInsurance: PropTypes.number,
};

 export default HeaderHouseInsurance;